.top-sections-container h3{
    text-align: center;
}

@media (max-width: 1668px) {
    .top-sections-container {
        flex-direction: column;
        align-items: center; /* Centre les sections horizontalement */
        margin-bottom: 20px;
    }

    .top-left-section, .top-right-section {
        flex: 1;
        padding: 20px;
        border-radius: 10px;
        background-color: #303030;
    }
}

.top-sections-container {
    display: flex;
    margin-bottom: 20px;
    margin: 0 auto;
    width: fit-content;
}

.top-left-section, .top-right-section {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
    background-color: #303030;
    text-align: left;


}

.top-left-section {
    margin-right: 10px;
}

.top-right-section {
    margin-right: 0;
}

.top-left-section iframe {
    width: 100%;
    height: 650px;
    border: none;
    border-radius: 10px;
}
.top-left-section2 iframe {
    width: 50%;
    height: 250px;
    border: none;
    border-radius: 10px;
    margin-bottom: 25px;

}

.top-right-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.top-right-section ul {
    list-style-type: none;
    padding: 0;
}

.top-right-section li {
    margin-bottom: 10px;
    font-size: 1rem;
}