/* frontend/src/components/Footer.css */
.rfooter {
    background-color: #003366;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 10px 0;
    bottom: 0;
    width: 100%;
    font-family: 'Proza Libre', sans-serif;
  }
  
  .rfooter button {
    background-color: #FFA500;
    border: none;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-family: 'Proza Libre', sans-serif;
  }
  
  .rfooter button:hover {
    background-color: #8b5b00;
    font-family: 'Proza Libre', sans-serif;
  }