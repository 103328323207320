@font-face {
  font-family: 'Proza Libre';
  src: url('../font/ProzaLibre-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Proza Libre';
  src: url('../font/ProzaLibre-Bold.ttf') format('truetype');
  font-weight: 700;
}

.App {
  text-align: center;
  background-color: #252525;
  color: #FFA500;
  font-family: 'Proza Libre', sans-serif;
  font-size: 18px;
}


.why_product{
  padding: 100px;
  background-color: #2c2c2c;
  color: #FFA500;
  font-family: 'Proza Libre', sans-serif;
  font-size: 18px;
  justify-content: center;
  font-size: 20px;

}
.why_product_desc p{
  color: #b8b8b8;
  font-family: 'Proza Libre', sans-serif;
  font-size: 20px;
  justify-content: center;
  margin: 0 auto;

}
.why_product_desc{
  /* display: flex; */
  text-align: left;
  margin-bottom: 20px;
  margin: 0 auto;
  width: 50%;
  padding-bottom: 55px;
}



.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 60px;
}

.App-header {
  position: relative;
  background-color: #003366;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFA500;
  text-align: left;
  background-image: url('../images/image_feature_completion.PNG'); /* Ajoutez votre image ici */
  background-size: cover;
  background-position: center;
  animation: backgroundAnimation 50s infinite alternate; /* Animation plus lente */
}
.App-header p {
  color: #ffffff;
}
.App-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #003366d0; /* Couleur noire avec transparence */
  z-index: 1;
}

.App-header > * {
  z-index: 2;
}

@keyframes backgroundAnimation {
  0% {
    background-position: center;
  }
  50% {
    background-position: top;
  }
  100% {
    background-position: center;
  }
}

.main-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.content-left {
  width: 50%;
}

.content-left h2 {
  font-size: 2.2em;
  margin-bottom: 10px;
}

.content-left p {
  font-size: 1.2em;
  line-height: 1.6;
}

.email-input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
}

.email-signup {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
}

.email-signup p {
  font-size: 0.8em;
  margin-top: 0px;
  font-family: 'Proza Libre', sans-serif;
}

.no-blue-link {
  color: inherit; /* Utilise la couleur du texte parent */
  text-decoration: none; /* Supprime le soulignement */
  color: #FFA500;
}

.email-signup input[type="email"] {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Proza Libre', sans-serif;
}

.email-signup button {
  background-color: #FFA500;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-family: 'Proza Libre', sans-serif;
}

.email-signup button:hover {
  background-color: #8b5b00;
  font-family: 'Proza Libre', sans-serif;
}

.block-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px auto;
  max-width: 1200px;
  padding: 20px;
}

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-content {
  width: 100%;
  text-align: left;
}

.block-content h3 {
  font-size: 1.8em;
  color: rgb(212, 212, 212);
}
.app h2{
  color: #FFA500 !important;
}
.block-content p {
  font-size: 1.2em;
  color: rgb(156, 156, 156);
}

.block-image {
  width: 30%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .block-image {
    display: none; /* Désactive l'image sur les appareils mobiles */
  }
}

.animate-right {
  transform: translateX(-20px);
}

.animate-left {
  transform: translateX(20px);
}

.block-image:hover {
  transform: translateX(0);
}

.faq-section {
  background-color: #333333;
  padding: 50px;
  text-align: left;
  max-width: 700px;
  margin: 0 auto;
  border-radius: 3px;
}

.faq-section h2 {
  color: #FFA500;
  font-size: 2.2em;
  margin-bottom: 20px;
}



.faq-item {
  margin-bottom: 20px;
}

.faq-item h4 {
  cursor: pointer;
  transition: color 0.3s ease;
  color: #bbbbbb; /* Change color on hover */
}

.faq-item h4:hover {
  color: #FFA500; /* Change color on hover */
}

.faq-item p {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  color: #9b9b9b; /* Change color on hover */

}

.faq-item p.show {
  max-height: 200px; /* Adjust based on content */
  opacity: 1;
}

@media (max-width: 768px) {
  .main-description {
    flex-direction: column;
    text-align: center;
  }

  .content-left {
    width: 100%;
    margin-bottom: 20px;
  }

  .block {
    flex-direction: column;
  }

  .block-image, .block-content {
    width: 100%;
  }
}

/* New CSS for the animated image */
.feature-image-container {
  display: flex;
  justify-content: center;
  margin: -10px 0;

}

.feature-image {
  width: 100%; /* 75% smaller */
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
}

.feature-image:hover {
  transform: scale(1.02);
}

.feature-image:hover::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.discord-section {
  /* background-color: #7289da; */
  color: white;
  padding: 50px;
  text-align: center;
  border-radius: 4px;
  /* margin: 40px auto; */
  max-width: 100%;
  background-color: #00336694;
  padding: 25px;
}

.discord-section h2 {
  font-size: 35px;
  margin-bottom: 20px;
  color: #FFA500;
}

.discord-section p {
  font-size: 18px;
  margin-bottom: 20px;
}

.discord-button {
  background-color: #FFA500;
  color: rgb(0, 0, 0);
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.discord-button:hover {
  background-color: #f8b12d;
}