.languageswitch_cont{
    display: flex;
    justify-content: Right;
    background-color: #003366;
}

.languageswitch_cont button {
    background-color: #ffa60057;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-family: 'Proza Libre', sans-serif;
    
}

.flaglabel{
    color: white;
    font-size: 18px;
    margin: 10px 8px;
    font-family: 'Proza Libre', sans-serif;
    display: flex;
    justify-content: center;
    margin-top: -5px;
}

.languageswitch_cont button:hover{
    background-color: #ffa600;
}

.flag {
    padding: 5px; /* Add padding to the flag images */
}