.chatcomp-all{
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #252525;
    color: rgb(212, 212, 212);
    font-family: 'Proza Libre', sans-serif;
    font-size: 18px;
}

.chatcomp-body {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 50px; /* Ajoute un espacement en bas */


}
.spacer_chatcomp {
    height: 40px;
}
.chatcomp-left, .chatcomp-right {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.chatcomp-right {
    width: 18%;
}

.chatcomp-left {
    padding-left: 35px;
}

@media (max-width: 1668px) {
    .chatcomp-body {
        flex-direction: column;
        align-items: center; /* Centre les panneaux horizontalement */
    }

    .chatcomp-left {
        width: 50%; /* Réduit la largeur de chatcomp-left en mode mobile */
        padding-right: 175px; /* Supprime le padding à gauche */
        margin: 0 0 10px 0; /* Ajoute une marge en bas */
    }

    .chatcomp-right {
        width: 100%;
        margin: 0 0 10px 0; /* Supprime les marges latérales et ajoute une marge en bas */
    }
}

textarea {
    font-size: 18px;
    width: 150%;
    height: 350px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: rgb(212, 212, 212);
    background-color: #303030;
}

input[type="number"],
input[type="text"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.temperature-slider {
    width: 50%;
}

.chatcomp-button-send {
    /* position: fixed; */
    /* display: none; */
    bottom: 0;
    /* width: 55%; */

    background-color: #ff9900;
    color: black;
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chatcomp-button-send:hover {
    background-color: #cc7a00;
}

.chatcomp-button-grey{
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #474747;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}
.chatcomp-button-grey:hover {
    background-color: #313131;
  }

.chatcomp-bottom-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 100px; /* Ajoute un espacement en haut */
}
.chatcomp-bottom {
    margin-top: 100px; /* Ajoute un espacement en haut */
}
.chatcomp-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* margin-top: 20px; */
    margin-bottom: 100px;
    background-color: #303030;
    padding: 50px;
}
.chatcomp-button-gray {
    background-color: #353535;
    color: white;
    border: none;
    width: 50%;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 10px;
}

.chatcomp-button-gray:hover {
    background-color: #303030;
}

.loading-spinner {
    border: 16px solid #252525; /* Light grey */
    border-top: 16px solid #ff9900; /* Orange */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 0 auto; /* Center the spinner */
    margin-top: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}