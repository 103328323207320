.not-found-container {
  text-align: center;
  padding: 50px;
  background-color: #252525;
  color: rgb(212, 212, 212);
  font-family: 'Proza Libre', sans-serif;
  height: 100vh; /* Make the container take up 100% of the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-container h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #FFA500;
}

.not-found-container p {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.not-found-container a {
  font-size: 1.2em;
  color: #007bff;
  text-decoration: none;
}

.not-found-container a:hover {
  text-decoration: underline;
}