.notification-popup {
  position: fixed;
  bottom: 10px; /* Positionné en bas */
  right: 10px; /* Positionné à droite */
  background-color: #333333e8;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  font-size: 12px;
  display: block; /* Assurez-vous que le popup est toujours affiché */
}

.close-button-notif {
  position: absolute;
  top: 5px;
  right: 5px; /* Positionné en haut à droite */
  background: none;
  border: none;
  color: rgb(255, 79, 79);
  font-size: 16px;
  cursor: pointer;
}

.finetuning-message_popup {
  color: green;
  font-size: 16px;
  margin-top: 10px;
}

.notification-message {
  font-size: 14px;
  margin-top: 10px;
}